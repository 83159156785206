import React from "react"
import Layout from "../../components/layout"
import logo from "../../images/candide_logo.png"
import monster from "../../images/candide_monster.jpg"

const Welcome = () => (
  
  <Layout title="Wilkommen">
    <div className="min-h-screen px-6 py-20 max-w-2xl mx-auto">
        <img src={monster} alt="Candide Reise" className="w-full max-w-2xl mx-auto object-coover" />
        <h1 className="text-5xl md:text-6xl font-display leading-none">Supaaaaaa, jetzt haben wir dich im System.</h1>
        <p className="mt-10 text-xl font-display">
            Achtung: Das ist Hrothgar, das <span className="ml-1 border-b border-canred">gruselige Email Monster</span>. 
            Wenn's dir mal zu doof ist mit den Nachrichten, kannst du ihn jederzeit abbestellen.
        </p>
        <p className="mt-8 text-xl font-display">Wir sind dann sehr traurig, but if you love somebody, let them unsubscribe.</p>
        <p className="mt-8 text-xl font-display">Und jetzt Backpapier, fertig, los.</p>
        <img src={logo} alt="Candide Schokoladen" className="mt-20 w-56 mx-auto" />
    </div>
  </Layout>
)

export default Welcome
